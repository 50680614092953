// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.
@if $enable-grid-classes {
    .form-group {
        margin-bottom: 0.5rem; // LMMS
    }
    .form-row {
        display: flex;
        flex-wrap: wrap;
        margin-right: -$form-grid-gutter-width / 2;
        margin-left: -$form-grid-gutter-width / 2;
        margin-bottom: 0.5rem; // LMMS
        >.col,
        >[class*="col-"] {
            padding-right: $form-grid-gutter-width / 2;
            padding-left: $form-grid-gutter-width / 2;
        }
        >.form-group {
            margin-bottom: 0;
        }
    }
}

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)
    // Because we use flex, the initial sizing of checkboxes is collapsed and
    // doesn't occupy the full-width (which is what we want for xs grid tier),
    // so we force that here.
    .form-check {
        width: 100%;
    }
    // Kick in the inline
    @include media-breakpoint-up(sm) {
        label {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
        }
        // Inline-block all the things for "inline"
        .form-group {
            display: flex;
            flex: 0 0 auto;
            flex-flow: row wrap;
            align-items: center;
            margin-bottom: 0.5rem;
        }
        // Allow folks to *not* use `.form-group`
        .form-control {
            display: inline-block;
            width: auto; // Prevent labels from stacking above inputs in `.form-group`
            vertical-align: middle;
        }
        // Make static controls behave like regular ones
        .form-control-plaintext {
            display: inline-block;
        }
        .input-group,
        .form-select {
            width: auto;
        }
        // Remove default margin on radios/checkboxes that were used for stacking, and
        // then undo the floating of radios and checkboxes to match.
        .form-check {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            padding-left: 0;
        }
        .form-check-input {
            position: relative;
            flex-shrink: 0;
            margin-top: 0;
            margin-right: $form-check-input-margin-x;
            margin-left: 0;
        }
    }
}